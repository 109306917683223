import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Member } from '../member/Member'
import styles from './Team.module.scss'

type teamProps = {
  title: string
  members: any[]
}

export const Team = ({ title, members }: teamProps) => (
  <>
    <h2 className={styles.title}>{title}</h2>
    <div className={styles.membersContainer}>
      {members.map((member) => {
        return (
          <Member
            profileUrl={member.member_image.url}
            hobbyUrl={member.hobby_score_icon.url}
            memberName={RichText.asText(member.member_name)}
            zenScore={member.zen_score}
            teaScore={member.tea_score}
            hobbyScore={member.hobby_score}
            className={styles.memberItem}
          />
        )
      })}
    </div>
  </>
)
