import React from 'react'
import { Card, Image, ListGroup } from 'react-bootstrap'
import { FaCoffee, FaHandPeace } from 'react-icons/fa'
import { Rating } from '../rating/Rating'
import styles from './Member.module.scss'

type memberProps = {
  profileUrl: string
  hobbyUrl: string
  memberName: string
  zenScore: number
  teaScore: number
  hobbyScore: number
  className: string
}

export const Member = ({
  profileUrl,
  hobbyUrl,
  memberName,
  zenScore,
  teaScore,
  hobbyScore,
  className,
}: memberProps) => (
  <Card className={className}>
    <Card.Img variant="top" src={profileUrl} />
    <Card.Body>
      <Card.Title>{memberName}</Card.Title>
      <Card.Text>
        <ListGroup>
          <ListGroup horizontal>
            <ListGroup.Item className={styles.item}>
              <FaHandPeace />
            </ListGroup.Item>
            <ListGroup.Item className={styles.item}>
              <Rating total={zenScore} />
            </ListGroup.Item>
          </ListGroup>
          <ListGroup horizontal>
            <ListGroup.Item className={styles.item}>
              <FaCoffee />
            </ListGroup.Item>
            <ListGroup.Item className={styles.item}>
              <Rating total={teaScore} />
            </ListGroup.Item>
          </ListGroup>
          <ListGroup horizontal>
            <ListGroup.Item className={styles.item}>
              <Image src={hobbyUrl} rounded />
            </ListGroup.Item>
            <ListGroup.Item className={styles.item}>
              <Rating total={hobbyScore} />
            </ListGroup.Item>
          </ListGroup>
        </ListGroup>
      </Card.Text>
    </Card.Body>
  </Card>
)
