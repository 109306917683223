export const ratingData = (rating: Number) => {
  switch (rating) {
    case 1:
      return ['star']
    case 2:
      return ['star', 'star']
    case 3:
      return ['star', 'star', 'star']
    case 4:
      return ['star', 'star', 'star', 'star']
    case 5:
      return ['star', 'star', 'star', 'star', 'star']
    default:
      return []
  }
}
