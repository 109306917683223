import React from 'react'
import { usePrismicDocumentByUID } from '@prismicio/react'
import { NotFound } from '../../components/notFound/NotFound'
import { NavigationBar } from '../../components/topMenu/NavigationBar'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { Video } from '../../components/video/Video'
import { Loader } from '../../components/loader/Loader'
import { Footer } from '../../components/footer/Footer'
import { Container } from 'react-bootstrap'
import styles from './VideoPage.module.scss'

export const VideosPage = () => {
  const [page, { state }] = usePrismicDocumentByUID('videos_page', 'video-page')

  if (state === 'loading') {
    return <Loader />
  }

  if (state === 'failed') {
    return <NotFound />
  }

  return (
    <>
      <NavigationBar />
      <Container>
        {page && (
          <>
            <h1 className={styles.videoTitle}>
              {RichText.asText(page.data.title)}
            </h1>
            {page.data.body.map(
              (slice: {
                primary: {
                  video_title: RichTextBlock[]
                  embedlink: RichTextBlock[]
                }
              }) => {
                return (
                  <Video
                    title={RichText.asText(slice.primary.video_title)}
                    embedLink={RichText.asText(slice.primary.embedlink)}
                  />
                )
              }
            )}
          </>
        )}
      </Container>
      <Footer />
    </>
  )
}
