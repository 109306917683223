import React from 'react'
import { usePrismicDocumentByUID } from '@prismicio/react'
import { NotFound } from '../../components/notFound/NotFound'
import { Talk } from '../../components/talk/Talk'
import { NavigationBar } from '../../components/topMenu/NavigationBar'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import styles from './TalksPage.module.scss'
import { Loader } from '../../components/loader/Loader'
import { Container } from 'react-bootstrap'
import { Footer } from '../../components/footer/Footer'

export const TalksPage = () => {
  const [page, { state }] = usePrismicDocumentByUID('talks_page', 'talks')

  if (state === 'loading') {
    return <Loader />
  }

  if (state === 'failed') {
    return <NotFound />
  }

  return (
    <>
      <NavigationBar />
      <Container>
        {page && (
          <>
            <h1 className={styles.talkGroupTitle}>
              {RichText.asText(page.data.page_title)}
            </h1>
            {page.data.body.map(
              (slice: {
                primary: {
                  date: RichTextBlock[]
                  talk_title: RichTextBlock[]
                  author: RichTextBlock[]
                  talk_link: string
                }
              }) => {
                return (
                  <Talk
                    date={RichText.asText(slice.primary.date)}
                    title={RichText.asText(slice.primary.talk_title)}
                    author={RichText.asText(slice.primary.author)}
                    url={slice.primary.talk_link}
                  />
                )
              }
            )}
          </>
        )}
        <Footer />
      </Container>
    </>
  )
}
