import React from 'react'
import { RichText } from 'prismic-reactjs'
import { usePrismicDocumentByUID } from '@prismicio/react'
import { NotFound } from '../../components/notFound/NotFound'
import { NavigationBar } from '../../components/topMenu/NavigationBar'
import { Footer } from '../../components/footer/Footer'
import { Loader } from '../../components/loader/Loader'
import { Container } from 'react-bootstrap'
import styles from './JoinPage.module.scss'

export const JoinPage = () => {
  const [page, { state }] = usePrismicDocumentByUID(
    'backstage_page',
    'backstage-page'
  )

  if (state === 'loading') {
    return <Loader />
  }

  if (state === 'failed') {
    return <NotFound />
  }

  return (
    <>
      <NavigationBar />
      <Container className={styles.container}>
        {page && (
          <>
            <h1 className={styles.joinTitle}>
              {RichText.asText(page.data.hiring_process_title)}
            </h1>
            <RichText render={page.data.hiring_process_description} />
          </>
        )}
      </Container>
      <Footer />
    </>
  )
}
