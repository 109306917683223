import React from 'react'
import { Carousel } from 'react-bootstrap'
import { usePrismicDocumentByUID } from '@prismicio/react'
import { NotFound } from '../../components/notFound/NotFound'
import { Footer } from '../../components/footer/Footer'
import { Loader } from '../../components/loader/Loader'
import { NavigationBar } from '../../components/topMenu/NavigationBar'

export const HomePage = () => {
  const [carousel, { state }] = usePrismicDocumentByUID(
    'home-page',
    'home-carousel'
  )

  if (state === 'loading') {
    return <Loader />
  }

  if (state === 'failed') {
    return <NotFound />
  }

  return (
    <>
      <NavigationBar />
      <Carousel>
        {carousel &&
          carousel.data.body.map(
            (slice: {
              primary: { carousel_image: { url: string | undefined } }
            }) => (
              <Carousel.Item key={slice.primary.carousel_image.url}>
                <img
                  className="d-block w-100"
                  src={slice.primary.carousel_image.url}
                  alt="team"
                />
              </Carousel.Item>
            )
          )}
      </Carousel>
      <Footer />
    </>
  )
}
