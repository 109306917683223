import React from 'react'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { usePrismicDocumentByUID } from '@prismicio/react'
import { NotFound } from '../../components/notFound/NotFound'
import { TitleAndParagraph } from '../../components/titleAndParagraph/TitleAndParagraph'
import { NavigationBar } from '../../components/topMenu/NavigationBar'
import { Footer } from '../../components/footer/Footer'
import { Loader } from '../../components/loader/Loader'
import { Container } from 'react-bootstrap'
import styles from './PillarsPage.module.scss'

export const PillarsPage = () => {
  const [pillars, { state }] = usePrismicDocumentByUID('pillars', 'pillars')

  if (state === 'loading') {
    return <Loader />
  }

  if (state === 'failed') {
    return <NotFound />
  }

  return (
    <>
      <NavigationBar />
      {pillars && (
        <Container className={styles.container}>
          <h1 className={styles.mainHeader}>
            {RichText.asText(pillars.data.title)}
          </h1>
          <p>
            <b>{RichText.asText(pillars.data.pillars_quote)}</b>
          </p>
          <p>{RichText.asText(pillars.data.introduction)}</p>
          {pillars.data.body.map(
            (slice: {
              primary: {
                pillar_title: RichTextBlock[]
                pillar_description: RichTextBlock[]
              }
            }) => {
              return (
                <TitleAndParagraph
                  title={RichText.asText(slice.primary.pillar_title)}
                  paragraph={RichText.asText(slice.primary.pillar_description)}
                />
              )
            }
          )}
        </Container>
      )}
      <Footer />
    </>
  )
}
