import React from 'react'
import { Link } from 'prismic-reactjs'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { usePrismicDocumentByUID } from '@prismicio/react'
import { FcAssistant, FcInTransit, FcOldTimeCamera } from 'react-icons/fc'
import { NotFound } from '../notFound/NotFound'
import styles from './Footer.module.scss'
import { Loader } from '../loader/Loader'

export const Footer = () => {
  const [footer, { state }] = usePrismicDocumentByUID(
    'footer-component',
    'melon-twits-footer'
  )

  if (state === 'loading') {
    return <Loader />
  }

  if (state === 'failed') {
    return <NotFound />
  }

  return (
    <Container className={styles.container} fluid>
      {footer && (
        <>
          <Row className="justify-content-md-center">
            <Col className={`${styles.rotate} ${styles.centerText}`} md="auto">
              <Image src={footer.data.left_melon_image.url} rounded />
            </Col>
            <Col
              className={`${styles.verticalAlignment} ${styles.centerText}`}
              md="auto"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href={Link.url(footer.data.left_link)}
              >
                <h3>
                  <FcAssistant />
                </h3>
              </a>
            </Col>
            <Col
              className={`${styles.verticalAlignment} ${styles.centerText}`}
              md="auto"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href={Link.url(footer.data.centre_link)}
              >
                <h3>
                  <FcInTransit />
                </h3>
              </a>
            </Col>
            <Col
              className={`${styles.verticalAlignment} ${styles.centerText}`}
              md="auto"
            >
              <a
                target="_blank"
                rel="noreferrer"
                href={Link.url(footer.data.right_link)}
              >
                <h3>
                  <FcOldTimeCamera />
                </h3>
              </a>
            </Col>
            <Col className={`${styles.rotate} ${styles.centerText}`} md="auto">
              <Image src={footer.data.right_melon_image.url} rounded />
            </Col>
          </Row>
          <Row className={styles.emailAddress}>
            <Col className={styles.centerText}>
              <p>melontwits@poweredbypercent.com</p>
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}
