import React from 'react'
import { usePrismicDocumentByUID } from '@prismicio/react'
import { NavigationBar } from '../../components/topMenu/NavigationBar'
import { Footer } from '../../components/footer/Footer'
import { Team } from '../../components/team/Team'
import { NotFound } from '../../components/notFound/NotFound'
import { RichText } from 'prismic-reactjs'
import { Loader } from '../../components/loader/Loader'
import { Container } from 'react-bootstrap'

type teamPageProps = {
  prismicSection: number
}

export const TeamPage = ({ prismicSection }: teamPageProps) => {
  const [page, { state }] = usePrismicDocumentByUID('page', 'the-melon-twits')

  if (state === 'loading') {
    return <Loader />
  }

  if (state === 'failed') {
    return <NotFound />
  }

  return (
    <>
      <NavigationBar />
      <Container>
        {page && (
          <Team
            title={RichText.asText(
              page.data.body[prismicSection].primary.team_name
            )}
            members={page.data.body[prismicSection].items}
          />
        )}
      </Container>
      <Footer />
    </>
  )
}
