import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { Link } from 'prismic-reactjs'
import styles from './Book.module.scss'

type bookProps = {
  name: string
  author: string
  url: string
}

export const Book = ({ name, author, url }: bookProps) => (
  <ListGroup horizontal>
    <span className={styles.lemon}>🍋</span>
    <ListGroup.Item className={styles.item}>
      <p>
        <a
          target="_blank"
          rel="noreferrer"
          className={styles.link}
          href={Link.url(url)}
        >
          <b>{name}</b>
        </a>
        <span className={styles.spanAuthor}>{author}</span>
      </p>
    </ListGroup.Item>
  </ListGroup>
)
