import React from 'react'
import styles from './TitleAndParagraph.module.scss'

type titleAndParagraphProps = {
  title: string
  paragraph: string
}

export const TitleAndParagraph = ({
  title,
  paragraph,
}: titleAndParagraphProps) => (
  <>
    <h2 className={styles.heading}>{title}</h2>
    <p>{paragraph}</p>
  </>
)
