import React from 'react'
import { ratingData } from './ratingData'
import { FaRocket } from 'react-icons/fa'
import { ListGroup } from 'react-bootstrap'
import styles from './Rating.module.scss'

type ratingProps = {
  total: Number
}

export const Rating = ({ total }: ratingProps) => {
  const ratingArray = ratingData(total)
  return (
    <ListGroup horizontal>
      {ratingArray.map(() => (
        <ListGroup.Item className={styles.item}>
          <FaRocket className={styles.rocket} />
        </ListGroup.Item>
      ))}
    </ListGroup>
  )
}
