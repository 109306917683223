import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { PillarsPage } from './pillars/PillarsPage'
import { TeamPage } from './teams/TeamPage'
import { JoinPage } from './join/JoinPage'
import { TalksPage } from './media/TalksPage'
import { HomePage } from './home/HomePage'
import { VideosPage } from './media/VideosPage'
import { BooksPage } from './media/BooksPage'

export const RouteList = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<PillarsPage />} path="/pillars" />
      <Route element={<TeamPage prismicSection={0} />} path="/argo" />
      <Route element={<TeamPage prismicSection={2} />} path="/delphi" />
      <Route element={<TeamPage prismicSection={3} />} path="/iced-latte" />
      <Route element={<TeamPage prismicSection={1} />} path="/matcha" />
      <Route element={<JoinPage />} path="/join" />
      <Route element={<TalksPage />} path="/talks" />
      <Route element={<VideosPage />} path="/videos" />
      <Route element={<BooksPage />} path="/books" />
      <Route element={<HomePage />} path="/home" />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  </BrowserRouter>
)
