import React from 'react'
import { Spinner } from 'react-bootstrap'
import styles from './Loader.module.scss'

export const Loader = () => (
  <div className={styles.spinner}>
    <Spinner animation="border" role="status" variant="primary">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
)
