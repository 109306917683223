import React from 'react'
import { Link } from 'prismic-reactjs'
import { ListGroup } from 'react-bootstrap'
import styles from './Talk.module.scss'

type talkProps = {
  date: string
  title: string
  author: string
  url: string
}

export const Talk = ({ date, title, author, url }: talkProps) => (
  <ListGroup horizontal>
    <span className={styles.lemon}>🍋</span>
    <ListGroup.Item className={styles.item}>
      <p>
        <span className={styles.spanDate}>{date}</span>
        <a
          target="_blank"
          rel="noreferrer"
          className={styles.link}
          href={Link.url(url)}
        >
          <b>{title}</b>
        </a>
        <span className={styles.author}>{author}</span>
      </p>
    </ListGroup.Item>
  </ListGroup>
)
