import React from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import styles from './NavigationBar.module.scss'

export const NavigationBar = () => (
  <Navbar bg="dark" variant="dark" collapseOnSelect expand="lg">
    <div className={styles.navbarContainer}>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Item>
            <Nav.Link href="/home">Home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/pillars">Pillars</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <NavDropdown title="Teams" id="basic-nav-dropdown">
              <NavDropdown.Item href="/argo">Argo</NavDropdown.Item>
              <NavDropdown.Item href="/delphi">Delphi</NavDropdown.Item>
              <NavDropdown.Item href="/iced-latte">Iced Latte</NavDropdown.Item>
              <NavDropdown.Item href="/matcha">Matcha</NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/join">Join</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <NavDropdown title="Media" id="basic-nav-dropdown">
              <NavDropdown.Item href="/talks">Talks</NavDropdown.Item>
              <NavDropdown.Item href="/books">Books</NavDropdown.Item>
              <NavDropdown.Item href="/videos">Videos</NavDropdown.Item>
            </NavDropdown>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </div>
  </Navbar>
)
