import React from 'react'
import styles from './Video.module.scss'

type videoProps = {
  title: string
  embedLink: string
}

export const Video = ({ title, embedLink }: videoProps) => (
  <>
    <h3 className={styles.title}>{title}</h3>
    <div className={styles.container}>
      <iframe
        title={title}
        className={styles.responsiveframe}
        src={embedLink}
      ></iframe>
    </div>
  </>
)
