import React from 'react'
import { usePrismicDocumentByUID } from '@prismicio/react'
import { NotFound } from '../../components/notFound/NotFound'
import { NavigationBar } from '../../components/topMenu/NavigationBar'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { Book } from '../../components/book/Book'
import { Loader } from '../../components/loader/Loader'
import { Footer } from '../../components/footer/Footer'
import { Container, ListGroup } from 'react-bootstrap'
import styles from './BookPage.module.scss'

export const BooksPage = () => {
  const [page, { state }] = usePrismicDocumentByUID('books_page', 'books')

  if (state === 'loading') {
    return <Loader />
  }

  if (state === 'failed') {
    return <NotFound />
  }

  return (
    <>
      <NavigationBar />
      <Container>
        {page && (
          <>
            <h1>{RichText.asText(page.data.body.books_title)}</h1>
            {page.data.body.map(
              (slice: {
                primary: { category_title: RichTextBlock[] }
                items: any[]
              }) => {
                return (
                  <>
                    <h2 className={styles.bookGroupTitle}>
                      {RichText.asText(slice.primary.category_title)}
                    </h2>
                    <ListGroup>
                      {slice.items.map((item) => {
                        return (
                          <Book
                            name={RichText.asText(item.book_name)}
                            author={RichText.asText(item.book_author)}
                            url={item.book_link}
                          />
                        )
                      })}
                    </ListGroup>
                  </>
                )
              }
            )}
          </>
        )}
      </Container>
      <Footer />
    </>
  )
}
